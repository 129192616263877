@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-image: url('./assets/bg2.jpg'); */
  /* background-size: fill;
  background-repeat: no-repeat; */
}
html,
body {
  /* background-color: gray; */
}

* {
  scroll-behavior: smooth;
}
